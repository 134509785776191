import React from "react";
import { Component } from "react";
import Header from "./Header";
import Loader from "./Loader";
import ShowingTextModal from "./ShowingTextModal";
import CryptoJS from "crypto-js";
import ShowingCompareResult from "./ShowingComapareResult";

const promptData = `Step 1: Check for tests with a failed status.
  Step 2: Verify heavy metal content against a list of known metals.
  Step 3: Check if heavy metal content exceeds threshold levels.
  Step 4: Identify known contaminants.
  Step 5: Evaluate contaminant levels against threshold levels
  Step 6 : Please check the provided text if it  contains any of the following substances : Sildenafil,Tadalafil,Sibutramine,Phenolphthalein,Fluoxetine,Steroids,DMAA (1,3-dimethylamylamine),Ephedrine,SARMs (Selective Androgen Receptor Modulators)
  Step 7 : Please check the provided text if it  contains any of the following substances : Arsenic,Cadmium,Lead,Mercury (Methyl Mercury),Chromium (Chromium VI),Bile-Tolerant Gram-Negative Bacteria,Escherichia coli,Salmonella,Pseudomonas aeruginosa,Staphylococcus aureus,Clostridia,Candida albicans
  Please return in below format
  {
    fail : from step 1 to 5, if found then return only substance or return not found,
    adulterants : from step 6, if found then return only substance or return not found,
    contamination : from step 7, if found then return only substance or return not found
  }
  `;

const adulterants = [
  "Sildenafil",
  "Tadalafil",
  "Sibutramine",
  "Phenolphthalein",
  "Fluoxetine",
  "Steroids",
  "DMAA",
  "DMAA (1,3-dimethylamylamine)",
  "Ephedrine",
  "Ephedrine, or SARMs (Selective Androgen Receptor Modulators)",
];
const contamination = [
  "Arsenic (As)",
  "Arsenic",
  "Cadmium",
  "Cadmium (Cd)",
  "Lead",
  "Lead (Pb)",
  "Mercury (Methyl Mercury)",
  "Chromium (Chromium VI)",
  "Mercury",
  "Chromium",
  "Mercury (Hg)",
];
const microbial = [
  "Bile-Tolerant Gram-Negative Bacteria",
  "Escherichia coli",
  "Salmonella",
  "Pseudomonas Aeruginosa",
  "Staphylococcus aureus",
  "Clostridia",
  "Candida albicans",
];
const acceptedResult = ["Not Found", "Not Found.", "Passed", "Pass"];
class FindingForbiddenSubstance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      documentList: [],
      loadingList: false,
      openModal: false,
      textValue: null,
      promptValue: null,
      loadingConvertText: false,
      textSearchModal: false,
      textData: null,
      data: null,
      sorting: null,
      order: "ASC",
      orgList: [],
      selectedOrg: null,
      loadingOrgList: false,
      documentListCopy: [],
      selectedFilter: null,
      selectedFilterOrg: null,
      currentDate: new Date(),
      openResultModal: false,
      value: null
    };
    this.fileInputRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({
      promptValue: promptData,
    });
    this.setState({ loadingList: true, loadingOrgList: true });
    let list = [];
    try {
      const org_list = await this.props.getOrgoanizationList();
      this.setState({ orgList: org_list, loadingOrgList: false });
      const res = await this.props.getForbiddenDocumentList();
      list = res?.data;
    } catch (error) {
      list = [];
      console.log(error);
    }
    this.setState({
      loadingList: false,
      documentList: list,
      documentListCopy: list,
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.sorting !== this.state.sorting ||
      prevState.order !== this.state.order
    ) {
      let list = [];
      try {
        this.setState({
          loadingList: true,
          documentList: [],
          documentListCopy: [],
        });
        const res = await this.props.getForbiddenDocumentList({
          sorting: this.state.sorting,
          order: this.state.order,
        });
        list = res?.data;
      } catch (error) {
        list = [];
        console.log(error);
      }
      this.setState({
        loadingList: false,
        documentList: list,
        documentListCopy: list,
      });
    }
  }

  calculateFileHash = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const fileBuffer = e.target.result;
          const wordArray = CryptoJS.lib.WordArray.create(
            new Uint8Array(fileBuffer)
          );
          const hash = CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Hex);
          resolve(hash);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  // ================ FOR FILE UPLOAD ================
  handleFileChange = async (event) => {
    this.setState({ loading: true });
    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    const file = event.target.files[0];

    let result = null;
    const dummy = await this.calculateFileHash(file)


    const payload = {
      hashList: [dummy],
      organizationIds: this.state.selectedOrg
        ? this.state.selectedOrg
        : this.state.orgList.map((data) => data.id),
    };
    formData.append("payload", JSON.stringify(payload))

    await this.props.uploadDocument(formData);

    setTimeout(async () => {
      let list = [];
      const res = await this.props.getForbiddenDocumentList({
        sorting: this.state.sorting,
        order: this.state.order,
      });
      list = res?.data;
      this.setState({
        loading: false,
        documentListCopy: list,
        documentList: list,
      });
    }, 20000);
    this.fileInputRef.current.value = "";
  };

  handleUpload = () => {
    this.fileInputRef.current.click();
  };

  // ================ SELECT ORGANIZATION ================
  handleSelectOrg = (event) => {
    this.setState({ selectedOrg: event.target.value });
  };

  // ================ FILTER ================
  handleFilter = (event, type) => {
    const arr = ["Pass", "Not able to read data"];
    // ================= MANUFACTUTING BRAND =================
    if (type === "brand") {
      this.setState({ selectedFilterOrg: event.target.value });
      let filter_data = [];
      if (event.target.value !== "None") {
        if (this.state.selectedFilter === "authentic_document_pass") {
          filter_data = this.state.documentListCopy.filter((data) => {
            return (
              data.manufacturing_brand === event.target.value &&
              arr.includes(data.result)
            );
          });
        } else if (this.state.selectedFilter === "authentic_document_fail") {
          filter_data = this.state.documentListCopy.filter((data) => {
            return (
              data.manufacturing_brand === event.target.value &&
              !arr.includes(data.result)
            );
          });
        } else {
          filter_data = this.state.documentListCopy.filter((data) => {
            return data.manufacturing_brand === event.target.value;
          });
        }
      } else if (event.target.value === "None") {
        if (this.state.selectedFilter === "None") {
          filter_data = this.state.documentListCopy;
        } else {
          filter_data = this.state.documentListCopy.filter((data) => {
            return this.state.selectedFilter === "authentic_document_pass"
              ? arr.includes(data.result)
              : !arr.includes(data.result);
          });
        }
      } else {
        filter_data = this.state.documentListCopy;
      }
      this.setState({ documentList: filter_data });
    }

    // ================= AUTHENTICATE DOCUMENT =================
    if (type === "filter") {
      this.setState({ selectedFilter: event.target.value });
      let filter_data = [];
      if (event.target.value !== "None") {
        if (this.state.selectedFilterOrg !== "None") {
          if (this.state.selectedFilterOrg !== null) {
            filter_data = this.state.documentListCopy.filter((data) => {
              return (
                data.manufacturing_brand === this.state.selectedFilterOrg &&
                (event.target.value === "authentic_document_pass"
                  ? arr.includes(data.result)
                  : !arr.includes(data.result))
              );
            });
          } else {
            filter_data = this.state.documentListCopy.filter((data) => {
              return event.target.value === "authentic_document_pass"
                ? arr.includes(data.result)
                : !arr.includes(data.result);
            });
          }
        } else if (this.state.selectedFilterOrg === "None") {
          filter_data = this.state.documentListCopy.filter((data) => {
            return event.target.value === "authentic_document_pass"
              ? arr.includes(data.result)
              : !arr.includes(data.result);
          });
        } else {
          filter_data = this.state.documentListCopy.filter((data) => {
            return event.target.value === "authentic_document_pass"
              ? arr.includes(data.result)
              : !arr.includes(data.result);
          });
        }
      } else if (event.target.value === "None") {
        if (
          this.state.selectedFilterOrg &&
          this.state.selectedFilterOrg !== "None"
        ) {
          filter_data = this.state.documentListCopy.filter((data) => {
            return data.manufacturing_brand === this.state.selectedFilterOrg;
          });
        } else {
          filter_data = this.state.documentListCopy;
        }
      } else {
        if (this.state.selectedFilterOrg) {
          filter_data = this.state.documentListCopy.filter((data) => {
            return data.manufacturing_brand === this.state.selectedFilterOrg;
          });
        } else {
          filter_data = this.state.documentListCopy;
        }
      }
      this.setState({ documentList: filter_data });
    }
  };
  render() {
    return (
      <div>
        <Header menuSelected="individualList" {...this.props} />
        <main className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="my-3 p-3 bg-white rounded shadow-sm"
                style={{ minHeight: "180px", fontSize: "14px" }}
              >
                <input
                  ref={this.fileInputRef}
                  type="file"
                  style={{ display: "none" }}
                  onChange={this.handleFileChange}
                />
                <h3
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="border-bottom border-gray pb-2 mb-0"
                >
                  <div>
                    <span
                      style={{ marginRight: "10px" }}
                      className="fa-stack fa-md"
                    >
                      <i className="fa fa-circle-thin fa-stack-2x"></i>
                      <i className="fa fa-list fa-stack-1x"></i>
                    </span>
                    Dashboard
                  </div>
                  <div
                    style={{
                      display: "flex",
                      maxWidth: "350px",
                      gap: "10px",
                      maxHeight: "40px",
                    }}
                  >
                    {/* <select
                      onChange={this.handleSelectOrg}
                      className="form-select"
                      disabled={this.state.loadingOrgList}
                    >
                      <option selected value={null}>
                        None
                      </option>
                      {this.state.orgList.map((org) => (
                        <option value={org.id}>{org.name}</option>
                      ))}
                    </select> */}
                    <button
                      onClick={this.handleUpload}
                      className="btn btn-primary pull-right"
                      style={{ minWidth: "150px" }}
                      disabled={this.state.loading}
                    >
                      {this.state.loading ? "Loading..." : "Verify Document"}
                    </button>
                  </div>
                </h3>
                <div
                  style={{ display: "flex", marginTop: "20px", gap: "14px" }}
                >
                  <div style={{ flexDirection: "column" }}>
                    <span style={{ fontSize: "12px" }}>Manufacturer/Brand</span>
                    <select
                      onChange={(event) => this.handleFilter(event, "brand")}
                      className="form-select"
                      disabled={this.state.loadingOrgList}
                      style={{ maxWidth: "300px", minWidth: "300px" }}
                    >
                      <option selected value="None">
                        All Organization
                      </option>
                      {this.state.orgList.map((org) => (
                        <option value={org.name}>{org.name}</option>
                      ))}
                    </select>
                  </div>

                  <div style={{ flexDirection: "column" }}>
                    <span style={{ fontSize: "12px" }}>Filters</span>
                    <select
                      onChange={(event) => this.handleFilter(event, "filter")}
                      className="form-select"
                      disabled={this.state.loadingOrgList}
                      style={{ maxWidth: "300px", minWidth: "300px" }}
                    >
                      <option selected value="None">
                        All
                      </option>
                      <option value="authentic_document_pass">
                        Authentic Document - Pass
                      </option>
                      <option value="authentic_document_fail">
                        Authentic Document - Fail
                      </option>
                    </select>
                  </div>

                  {/* <div style={{ flexDirection: "column" }}>
                    <span style={{ fontSize: "12px" }}>Filters</span>
                    <select
                      onChange={(event) => this.handleFilter(event, "filter")}
                      className="form-select"
                      disabled={this.state.loadingOrgList}
                      style={{ maxWidth: "300px", minWidth: "300px" }}
                    >
                      <option selected value={null}>
                        None
                      </option>
                      <option value="passed">Passed</option>
                      <option value="failed">Failed</option>
                    </select>
                  </div> */}
                  {/* <div>
                    <button
                      style={{ minWidth: "150px", height: "40px" }}
                      className="btn btn-primary pull-right"
                    >
                      Search
                    </button>
                  </div> */}
                </div>

                <br />
                {this.state.loadingList && <Loader />}
                <table
                  className="table table-striped"
                  style={{
                    tableLayout: "fixed",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          textAlign: "center",
                          // width: "10%",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      // onClick={() => {
                      //   this.setState({
                      //     sorting: "product_name",
                      //     order: this.state.order === "DESC" ? "ASC" : "DESC",
                      //   });
                      // }}
                      >
                        <span style={{ marginRight: "15px" }}>
                          Product name
                        </span>
                        {/* <i
                          className={
                            this.state.order === "ASC"
                              ? "fa fa-arrow-up"
                              : "fa fa-arrow-down"
                          }
                        ></i> */}
                      </th>
                      <th
                        scope="col"
                        style={{
                          textAlign: "center",
                          // width: "10%",
                          fontSize: "12px",
                          fontWeight: "bold",
                          wordBreak: "break-word",
                        }}
                      >
                        Manufacturer/Brand
                      </th>
                      <th
                        scope="col"
                        style={{
                          textAlign: "center",
                          // width: "10%",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Batch Name
                      </th>
                      <th
                        scope="col"
                        style={{
                          textAlign: "center",
                          // width: "10%",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Lab Name
                      </th>
                      <th
                        scope="col"
                        style={{
                          textAlign: "center",
                          // width: "10%",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Authentic COA
                      </th>
                      <th
                        scope="col"
                        style={{
                          textAlign: "center",
                          // width: "10%",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Microbial Testing
                      </th>
                      <th
                        scope="col"
                        style={{
                          textAlign: "center",
                          // width: "10%",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Metal Testing
                      </th>
                      <th
                        scope="col"
                        style={{
                          textAlign: "center",
                          // width: "10%",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Adulterants Testing
                      </th>
                      <th
                        scope="col"
                        style={{
                          textAlign: "center",
                          // width: "10%",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Substance result
                      </th>
                      <th
                        scope="col"
                        style={{
                          textAlign: "center",
                          // width: "10%",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Label Verification
                      </th>
                      <th
                        scope="col"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        style={{
                          textAlign: "center",
                          // width: "10%",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Lab Credentials
                      </th>
                      <th
                        scope="col"
                        style={{
                          verticalAlign: "middle",
                          // width: "10%",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Manufacturer Credentials
                      </th>
                      <th
                        scope="col"
                        style={{
                          verticalAlign: "middle",
                          fontSize: "12px",
                          fontWeight: "bold",
                          paddingLeft: "20px"
                        }}
                      >
                        Product Documents
                      </th>
                    </tr>
                  </thead>
                  {!this.state.loadingList &&
                    this.state.documentList.length === 0 ? (
                    <tbody className="text-center">
                      {/* <tr> */}
                      <td className="pt-4" colSpan="7">
                        No Record Found
                      </td>
                      {/* </tr> */}
                    </tbody>
                  ) : (
                    <tbody>
                      {(this.state.documentList || []).map((data) => (
                        <tr>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                              wordWrap: "break-word",
                            }}
                          >
                            <a
                              href={data?.document_url}
                              style={{ wordWrap: "break-word" }}
                              target="_blank"
                            >
                              {data?.product_name ?? "-"}
                            </a>
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                              wordWrap: "break-word",
                            }}
                          >
                            {data?.manufacturing_brand ?? "-"}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                              wordWrap: "break-word",
                            }}
                          >
                            {data?.batch_id ?? "-"}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                              wordWrap: "break-word",
                            }}
                          >
                            {data?.verify_org_name ?? "-"}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                              wordWrap: "break-word",
                            }}
                          >
                            <img
                              src={
                                data?.result === "Pass" ||
                                  data?.result === "Not able to read data"
                                  ? "success.png"
                                  : "rejected.png"
                              }
                              alt="img"
                              width="25px"
                              height="25px"
                            />
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                              wordWrap: "break-word",
                            }}
                          >
                            {microbial.filter((substance) =>
                              data?.result?.split(",").includes(substance)
                            ).length > 0
                              ? microbial
                                .filter((substance) =>
                                  data?.result?.split(",").includes(substance)
                                )
                                .join(",")
                              : "Pass"}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                              wordWrap: "break-word",
                            }}
                          >
                            {contamination.filter((substance) =>
                              data?.result?.split(",").includes(substance)
                            ).length > 0
                              ? contamination
                                .filter((substance) =>
                                  data?.result?.split(",").includes(substance)
                                )
                                .join(",")
                              : "Pass"}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                              wordWrap: "break-word",
                            }}
                          >
                            {adulterants.filter((substance) =>
                              data?.result?.split(",").includes(substance)
                            ).length > 0
                              ? adulterants
                                .filter((substance) =>
                                  data?.result?.split(",").includes(substance)
                                )
                                .join(",")
                              : "Pass"}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                              wordWrap: "break-word",
                            }}
                          >
                            <i
                              onClick={() =>
                                this.setState({
                                  openResultModal: true,
                                  value: data?.finding_substace,
                                })
                              }
                              style={{ fontSize: "15px", cursor: "pointer" }}
                              class="fa fa-eye"
                            ></i>
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                              wordWrap: "break-word",
                            }}
                          >
                            <i
                              onClick={() =>
                                this.setState({
                                  openResultModal: true,
                                  value: data?.lable_verification,
                                })
                              }
                              style={{ fontSize: "15px", cursor: "pointer" }}
                              class="fa fa-eye"
                            ></i>
                            {/* {data?.lable_verification} */}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                            }}
                          >
                            {new Date(data?.created_on).toLocaleDateString(
                              "en-US",
                              {
                                day: "2-digit",
                                month: "short",
                              }
                            ) +
                              " " +
                              " , " +
                              new Date(data?.created_on).toLocaleTimeString(
                                "en-US",
                                { hour: "2-digit", minute: "2-digit" }
                              )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                              justifyContent: "center",
                              wordWrap: "break-word",
                            }}
                          >
                            {JSON.parse(data?.certificate) ? (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Total certificate"
                                  >
                                    {JSON.parse(data?.certificate)?.length}
                                  </span>
                                  (
                                  <span
                                    // data-toggle="tooltip"
                                    data-placement="top"
                                    title="Fail certificate"
                                  >
                                    {
                                      (
                                        JSON.parse(data?.certificate) ?? []
                                      )?.filter(
                                        (val) =>
                                          new Date(val?.effective_date) >
                                          this.state.currentDate
                                      )?.length
                                    }
                                  </span>
                                  )
                                </p>
                                <i
                                  onClick={() => {
                                    this.setState({
                                      openModal: true,
                                      data: JSON.parse(data?.certificate),
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="fa fa-eye"
                                ></i>
                              </div>
                            ) : (
                              "Not available"
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                              justifyContent: "center",
                              wordWrap: "break-word",
                            }}
                          >
                            {JSON.parse(data?.manufacturing_certificate) ? (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Total certificate"
                                  >
                                    {
                                      JSON.parse(
                                        data?.manufacturing_certificate
                                      )?.length
                                    }
                                  </span>
                                  (
                                  <span
                                    // data-toggle="tooltip"
                                    data-placement="top"
                                    title="Fail certificate"
                                  >
                                    {
                                      (
                                        JSON.parse(
                                          data?.manufacturing_certificate
                                        ) ?? []
                                      )?.filter(
                                        (val) =>
                                          new Date(val?.effective_date) >
                                          this.state.currentDate
                                      )?.length
                                    }
                                  </span>
                                  )
                                </p>
                                <i
                                  onClick={() => {
                                    this.setState({
                                      openModal: true,
                                      data: JSON.parse(
                                        data?.manufacturing_certificate
                                      ),
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="fa fa-eye"
                                ></i>
                              </div>
                            ) : (
                              "Not available"
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                              wordWrap: "break-word",
                              cursor: data?.product_document_url ? "pointer" : "not-allowed"
                            }}
                          >
                            <a href={data?.product_document_url} target="_blank">
                              <i class="fa fa-file-text" aria-hidden="true"></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </main>
        <ShowingTextModal
          show={this.state.openModal}
          onClose={() =>
            this.setState({
              openModal: false,
              data: null,
            })
          }
          value={this.state.data}
        />
        <ShowingCompareResult
          show={this.state.openResultModal}
          value={this.state.value}
          onClose={() => this.setState({ openResultModal: false, value: null })}
        />
      </div>
    );
  }
}

export default FindingForbiddenSubstance;
