import React, { useEffect, useState } from "react";
import { getForbiddenDocumentListFromText } from "../redux/actions";
import { useDispatch } from "react-redux";

const ShowingTextModal = ({
  show,
  onClose,
  value = [],
  is_text_search_result,
}) => {
  const modalStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.4)",
  };

  const modalContentStyles = {
    backgroundColor: "#fefefe",
    padding: "20px",
    border: "1px solid #888",
    width: "80%",
    maxWidth: "800px",
    borderRadius: "5px",
    position: "relative",
    boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
    maxHight: "500px",
    overflowY: "auto",
  };

  const closeStyles = {
    color: "#aaa",
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "28px",
    fontWeight: "bold",
    cursor: "pointer",
  };

  const footerStyles = {
    // marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
    // gap: "10px",
  };

  const currentDate = new Date();

  if (!show) {
    return null;
  }
  return (
    <div style={modalStyles} onClick={onClose}>
      <div style={modalContentStyles} onClick={(e) => e.stopPropagation()}>
        <span style={closeStyles} onClick={onClose}>
          &times;
        </span>
        <h3>Organization Certificates</h3>
        <div className="my-3 p-3 bg-white rounded shadow-sm">
          <table
            className="table table-striped"
            style={{ tableLayout: "auto" }}
          >
            <thead>
              <tr>
                <th
                  scope="col"
                  style={{
                    verticalAlign: "middle",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Certificate Name
                </th>
                <th
                  scope="col"
                  style={{
                    verticalAlign: "middle",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Authentic Document
                </th>
                <th
                  scope="col"
                  style={{
                    verticalAlign: "middle",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Effective Date
                </th>
              </tr>
            </thead>
            {value?.length === 0 ? (
              <tbody className="text-center">
                <td className="pt-4" colSpan="7">
                  No Record Found
                </td>
              </tbody>
            ) : (
              <tbody>
                {value?.map((val) => (
                  <tr>
                    <td><a href={val?.url} target="_blank">{val?.title}</a></td>
                    <td>
                      {new Date(val?.effective_date) > currentDate
                        ? "Pass"
                        : "Fail"}
                    </td>
                    <td>
                      {new Date(val?.effective_date).toLocaleDateString(
                        "en-US",
                        {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        }
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        <div style={footerStyles}>
          <button className="btn btn-primary" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShowingTextModal;
